import React from 'react';
import { IEntity, IEntityListState, EntityListState, IEntityChildren, IBaseComponentProps, IEntityDescription1 } from '../../types';
import styles from '../../styles/cr.module.scss';
import { FilteredList } from '../cr/FilteredList';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { DateService } from '../../services';
import { IColumn, IObjectWithKey, Selection } from '@fluentui/react/lib/DetailsList';
import { IUseApiProps } from '../useApi';
import { IWithErrorHandlingProps } from '../withErrorHandling';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

export interface ITasksEntityListProps<T extends IEntity> extends IBaseComponentProps, IWithErrorHandlingProps, IUseApiProps {
    entity: string;
    entityName: { Plural: string, Singular: string };
    barColorBeforeTitle?: string;
    addChild?: { Name: string };
    enableShowHideClosedEntities?: boolean;
    columns: IColumn[];
    loadListItems: (showClosedEntities: boolean) => Promise<T[]>;
    mapEntitiesToListItems: (entities: T[]) => IObjectWithKey[];
    entityForm?: (showForm: boolean, entityId: number, onSaved: () => void, onCancelled: () => void, entityDescription1: string, entityDescription1DirectorateID: number) => React.ReactElement;
    viewForm?: (showForm: boolean, entityId: number, onClosed: () => void, entityDescription1: string) => React.ReactElement;
    childEntityForm?: (showForm: boolean, parentEntityId: number, onSaved: () => void, onCancelled: () => void) => React.ReactElement;
    onCheckDelete: (entityId: number) => Promise<IEntityChildren[]>;
    onDelete: (entityId: number) => Promise<void>;
    onChange?: () => void;
    disableAdd?: boolean;
    disableDelete?: (entity: T) => boolean;
    addMultiple?: boolean;
    additionalItemsOnSelection?: (entity: T) => ICommandBarItemProps[];
    preFarItems?: (entity: T) => ICommandBarItemProps[];
    preFarItemsOnSelection?: (entity: T) => ICommandBarItemProps[];
    showEntityDescription1?: boolean;
    loadEntityDescription1?: () => Promise<IEntityDescription1>;

    onChangeSelectedTask?: (number) => void;
    loadDataCounter?: number;
    stageID?: number;
    customStylesForList?: any;
    clearSelection?: boolean;
    selectionPreservedOnEmptyClick: boolean;
}

export class TasksEntityList<T extends IEntity> extends React.Component<ITasksEntityListProps<T>, IEntityListState<T>> {
    private _selection: Selection;

    constructor(props: ITasksEntityListProps<T>) {
        super(props);
        this.state = new EntityListState<T>();
        this._selection = new Selection({
            onSelectionChanged: () => {
                if (this._selection.getSelectedCount() === 1) {
                    const key = Number(this._selection.getSelection()[0].key);
                    const enableView = props.viewForm ? true : false;
                    this.setState({ SelectedEntity: key, EnableEdit: true, EnableView: enableView, EnableDelete: true, EnableAddChild: true });
                    props.onChangeSelectedTask(key);
                } else {
                    this.setState({ SelectedEntity: null, EnableEdit: false, EnableView: false, EnableDelete: false, EnableAddChild: false });
                    props.onChangeSelectedTask(null);
                }
            }
        });
    }

    public render(): React.ReactElement<ITasksEntityListProps<T>> {
        const {
            columns,
            mapEntitiesToListItems } = this.props;
        const {
            LoadingListData,
            LoadingDeleteCheck,
            Entities,
            ListFilterText, } = this.state;

        return (
            <div style={{ width: 'calc(100% - 10px)' }} className={`${styles.cr} ${this.props.isFullPage ? styles.crFullPage : ''}`}>
                <CrLoadingOverlay isLoading={LoadingDeleteCheck} opaque={false} />
                <div data-is-scrollable={this.props.isFullPage ? 'true' : 'false'} className={styles.crFullPageList} style={{ position: 'relative', minHeight: 'auto', marginBottom: '40px' }}>
                    <CrLoadingOverlay style={{ paddingTop: '15px' }} isLoading={LoadingListData} opaque={true} />
                    <FilteredList
                        columns={columns}
                        items={{ Timestamp: Entities.Timestamp, Items: mapEntitiesToListItems(Entities.Items) }}
                        selection={this._selection}
                        filterText={ListFilterText}
                        customStyles={this.props.customStylesForList}
                        selectionPreservedOnEmptyClick={this.props.selectionPreservedOnEmptyClick}
                    />
                </div>
            </div>
        );
    }

    //#region Form initialisation

    public componentDidMount(): void {
        if (this.props.apiConnected) {
            this.loadEntities();
        }
    }

    public componentDidUpdate(prevProps: ITasksEntityListProps<T>): void {
        if (prevProps.apiConnected !== this.props.apiConnected ||
            prevProps.entity !== this.props.entity ||
            prevProps.loadDataCounter !== this.props.loadDataCounter) {
            this.loadEntities();
        }
        if (prevProps.clearSelection !== this.props.clearSelection) {
            if (this.props.clearSelection === true) {
                this.clearSelection();
            }
        }
    }

    private loadEntities = async (): Promise<void> => {
        const { entityName, loadListItems, showEntityDescription1, loadEntityDescription1 } = this.props;
        this.setState({ LoadingListData: true });
        try {
            if (showEntityDescription1 === true) {
                const entityDescription1: IEntityDescription1 = await loadEntityDescription1();
                this.setState({
                    EntityDescription1ForList: entityDescription1.Description1ForList,
                    EntityDescription1ForForm: entityDescription1.Description1ForForm,
                    EntityDescription1DirectorateID: entityDescription1.DirectorateID,
                });
            }
            const items = await loadListItems(this.state.ShowClosedEntities);
            if (items) {
                this.setState({ Entities: { Timestamp: DateService.timestamp(), Items: items } });
            }
        }
        catch (err) {
            this.props.errorHandling?.onError(`Error loading ${entityName.Plural || 'items'} `, err.message);
        } finally {
            this.setState({ LoadingListData: false });
        }
    }

    //#endregion

    //#region Form infrastructure

    private clearSelection = (): void => {
        if (this.state.SelectedEntity) {
            this._selection.setKeySelected(this.state.SelectedEntity.toString(), false, false);
        }
        this.setState({ SelectedEntity: null });
    }

    //#endregion
}
