import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { IGIAAUpdate, GIAAUpdate, IEntity } from '../../types';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { CrCheckbox } from '../cr/CrCheckbox';
import { FormButtons } from '../cr/FormButtons';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { FormCommandBar } from '../cr/FormCommandBar';
import { CrDatePicker } from '../cr/CrDatePicker';
import { FieldErrorMessage } from '../cr/FieldDecorators';
import { GIAAUpdateTypes } from '../../types/AppGlobals';
import styles from '../../styles/cr.module.scss';
import { changeDatePicker } from '../../types/AppGlobals';
import { DateService } from '../../services';

export interface IUpdatesSaveFormProps extends types.IBaseComponentProps {
    giaaRecommendationId: number | string;
    updateType: string;
    defaultActionStatusTypeId: number;
    defaultRevDate: Date;
    targetDate: Date;
    entityId: number;
    showForm: boolean;
    onSaved?: (defaultGIAAActionStatusTypeId: number, defaultRevisedDate: Date) => void;
    onCancelled?: () => void;
}

export interface ILookupData {
    GIAAActionStatusTypes: IEntity[];
}
export class LookupData implements ILookupData {
    public GIAAActionStatusTypes = null;
}
export interface IErrorMessage {
    Details: string;
    RevisedDate: string;
    ActionStatus: string;
    FileUpload: string;
    RequestDateChangeTo: string;
}
export class ErrorMessage implements IErrorMessage {

    public Details = null;
    public RevisedDate = null;
    public ActionStatus = null;
    public FileUpload = null;
    public RequestDateChangeTo = null;
}
export interface IUpdatesSaveFormState {
    Loading: boolean;
    LookupData: ILookupData;
    FormData: IGIAAUpdate;
    FormDataBeforeChanges: IGIAAUpdate;
    FormIsDirty: boolean;
    ErrMessages: IErrorMessage;
    UploadStatus: string;
    UploadProgress: number;
    ShowUploadProgress: boolean;
}
export class UpdatesSaveFormState implements IUpdatesSaveFormState {
    public Loading = false;
    public LookupData = new LookupData();
    public FormData;
    public FormDataBeforeChanges;
    public FormIsDirty = false;
    public ErrMessages = new ErrorMessage();
    public UploadStatus = "";
    public UploadProgress: number = 0;
    public ShowUploadProgress = false;

    constructor(giaaRecommendationId: number, updateType: string) {
        this.FormData = new GIAAUpdate(giaaRecommendationId, updateType);
        this.FormDataBeforeChanges = new GIAAUpdate(giaaRecommendationId, updateType);
    }
}

export default class UpdatesSaveForm extends React.Component<IUpdatesSaveFormProps, IUpdatesSaveFormState> {
    private giaaActionStatusTypeService: services.GIAAActionStatusTypeService = new services.GIAAActionStatusTypeService();
    private giaaUpdateService: services.GIAAUpdateService = new services.GIAAUpdateService();
    private zFileService: services.ZFileService = new services.ZFileService();
    constructor(props: IUpdatesSaveFormProps, state: IUpdatesSaveFormState) {
        super(props);
        this.state = new UpdatesSaveFormState(Number(props.giaaRecommendationId), props.updateType);
    }

    public render(): React.ReactElement<IUpdatesSaveFormProps> {
        return (
            <Panel isOpen={this.props.showForm} headerText={this.getHeaderText()} type={PanelType.medium} onRenderNavigation={() => <FormCommandBar onSave={this.saveData} onCancel={this.props.onCancelled} /*saveDisabled={this.state.ShowUploadProgress}*/ />}>
                <div className={styles.cr}>
                    {this.renderFormFields()}
                    <FormButtons
                        primaryText={"Save"}
                        onPrimaryClick={() => this.saveData()}
                        primaryDisabled={this.state.ShowUploadProgress}
                        onSecondaryClick={this.props.onCancelled}
                    />
                </div>
            </Panel>
        );
    }

    public renderFormFields() {
        return (
            <React.Fragment>
                {this.renderReqCloseCheckBox()}
                {this.renderReqDateChangeCheckBox()}
                {this.renderReqDateChangeTo()}
                {this.renderMarkAllReqCloseCheckBox()}
                {this.renderGIAAActionStatusTypes()}
                {this.renderRevisedDate()}
                {this.renderUpdateDetails()}
                {this.renderEvLabel()}
                {this.renderEvCheckBox()}
                {this.renderEvLinkBox()}
                {this.renderFileUpload()}

            </React.Fragment>
        );
    }

    private renderReqCloseCheckBox() {
        if (this.props.updateType !== GIAAUpdateTypes.ActionUpdate) return null;

        if (this.state.FormData.RequestDateChange === true) return null;

        return (
            <React.Fragment>
                <CrCheckbox
                    className={`${styles.formField} ${styles.checkbox}`}
                    label="Please check this box to request actions to be closed, provide details in the box provided and upload any supporting evidence."
                    checked={this.state.FormData.RequestClose}
                    onChange={(ev, isChecked) => this.changeCheckbox_Req(isChecked, "RequestClose")}
                />
            </React.Fragment>
        );
    }

    private renderReqDateChangeCheckBox() {
        if (this.props.updateType !== GIAAUpdateTypes.ActionUpdate) return null;

        if (this.state.FormData.RequestClose === true) return null;

        return (
            <React.Fragment>
                <CrCheckbox
                    className={`${styles.formField} ${styles.checkbox}`}
                    label="Please check this box if you wish to revise the implementation date. By doing so you confirm there is a valid reason for changing the date, which has been approved by the responsible director."
                    checked={this.state.FormData.RequestDateChange}
                    onChange={(ev, isChecked) => this.changeCheckbox_Req(isChecked, "RequestDateChange")}
                />
            </React.Fragment>
        );
    }

    private renderReqDateChangeTo() {
        if (this.props.updateType !== GIAAUpdateTypes.ActionUpdate) return null;

        if (this.state.FormData.RequestClose === true) return null;

        let required: boolean = false;
        if (this.state.FormData.RequestDateChange === true) {
            required = true;
            console.log('RequestDateChangeTo set to required');
        }

        return (
            <CrDatePicker
                className={styles.formField}
                required={required}
                value={DateService.removeTimezoneOffset(this.state.FormData.RequestDateChangeTo)}
                onSelectDate={(v) => changeDatePicker(this, v, "RequestDateChangeTo")}
                errorMessage={this.state.ErrMessages.RequestDateChangeTo}
            />
        );
    }


    private renderMarkAllReqCloseCheckBox() {
        if (this.props.updateType !== GIAAUpdateTypes.Status_DateUpdate) return null;

        return (
            <React.Fragment>
                <CrCheckbox
                    className={`${styles.formField} ${styles.checkbox}`}
                    label="Mark any outstanding status close or revise implementation date requests from action owners"
                    checked={this.state.FormData.MarkAllReqClosed}
                    onChange={(ev, isChecked) => this.changeCheckbox(isChecked, "MarkAllReqClosed")}
                />
            </React.Fragment>
        );

    }
    private renderGIAAActionStatusTypes() {
        if (this.props.updateType !== GIAAUpdateTypes.Status_DateUpdate) return null;

        const giaaActionStatusTypes = this.state.LookupData.GIAAActionStatusTypes;
        if (giaaActionStatusTypes) {
            return (
                <CrDropdown
                    label="Revised Recommendation Status"
                    placeholder="Select an Option"
                    required={true}
                    className={styles.formField}
                    options={services.LookupService.entitiesToSelectableOptions(giaaActionStatusTypes)}
                    selectedKey={this.state.FormData.GIAAActionStatusTypeId}
                    onChange={(_, v) => this.changeDropdown(v, "GIAAActionStatusTypeId")}
                    errorMessage={this.state.ErrMessages.ActionStatus}
                />
            );
        }
        else
            return null;
    }

    private renderRevisedDate() {
        if (this.props.updateType !== GIAAUpdateTypes.Status_DateUpdate) return null;

        return (
            <CrDatePicker
                label="Revised Implementation Date"
                className={styles.formField}
                //value={this.state.FormData.RevisedDate}
                value={DateService.removeTimezoneOffset(this.state.FormData.RevisedDate)}
                onSelectDate={(v) => changeDatePicker(this, v, "RevisedDate")}
                errorMessage={this.state.ErrMessages.RevisedDate}
            />
        );
    }

    private renderUpdateDetails() {

        let lbl: string = "";
        if (this.props.updateType === GIAAUpdateTypes.ActionUpdate)
            lbl = "Action Update Details";
        else if (this.props.updateType === GIAAUpdateTypes.Status_DateUpdate)
            lbl = "Reason for Revision";
        else if (this.props.updateType === GIAAUpdateTypes.GIAAComment)
            lbl = "Auditors Comment";
        else
            lbl = "Add Comment or Feedback";

        return (
            <CrTextField
                label={lbl}
                className={styles.formField}
                value={this.state.FormData.UpdateDetails}
                onChange={(_, v) => this.changeTextField(v, "UpdateDetails")}
                multiline={true}
                required={true}
                errorMessage={this.state.ErrMessages.Details}
                rows={3}
            />
        );
    }


    private renderEvLabel() {
        let lbl: string = "";
        if (this.props.updateType === GIAAUpdateTypes.ActionUpdate) {
            if (this.state.FormData.RequestClose === true) {
                lbl = "Upload Evidence";
            }
            else {
                lbl = "Optional link or evidence upload";
            }
        }

        else if (this.props.updateType === GIAAUpdateTypes.Status_DateUpdate)
            lbl = "Provide supporting evidence if necessary";
        else if (this.props.updateType === GIAAUpdateTypes.GIAAComment)
            lbl = "Optional link or supporting pdf upload";
        else
            lbl = "Optional link or supporting pdf upload";

        return (
            <div>{lbl}</div>
        );
    }
    private renderEvCheckBox() {
        if (this.props.updateType === GIAAUpdateTypes.Status_DateUpdate) return null;

        if (this.state.FormData.RequestClose === true) return null;

        return (
            <React.Fragment>
                <CrCheckbox
                    className={`${styles.formField} ${styles.checkbox}`}
                    label="Provide a link instead of uploading a file"
                    checked={this.state.FormData.EvIsLink}
                    onChange={(ev, isChecked) => this.changeCheckbox(isChecked, "EvIsLink")}
                />
            </React.Fragment>
        );

    }

    private renderEvLinkBox() {
        if (this.state.FormData.EvIsLink === true) {

            return (
                <CrTextField
                    label="Link"
                    className={styles.formField}
                    value={this.state.FormData.EvFileName}
                    onChange={(_, v) => this.changeTextField(v, "EvFileName")}
                />
            );
        }
        else
            return false;
    }

    private renderFileUpload() {
        if (this.state.FormData.EvIsLink === true)
            return null;

        return (
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <div>
                    <input type="file" name="fileUpload" id="fileUpload" accept=".pdf,.xlsx,.xls"></input>
                    {this.state.ErrMessages.FileUpload && <FieldErrorMessage value={this.state.ErrMessages.FileUpload} />}
                    <div style={{ paddingTop: '10px' }}>
                        Upload evidence files as Excel XLSX files or as PDFs.
                        {/* For guidance on savings documents as PDFs, please click <span onClick={this.viewHelpPDF} style={{ textDecoration: 'underline', cursor: 'pointer' }}>here</span>. */}
                    </div>
                </div>
                {this.state.ShowUploadProgress && <div style={{ minHeight: '80px', marginTop: '15px' }}>
                    <div>
                        {this.state.UploadStatus}
                    </div>
                    <div>
                        {this.state.UploadProgress} %
                    </div>
                </div>}
            </div>
        );
    }


    private viewHelpPDF = () => {
        console.log('help pdf');
        // const fileName: string = "HowToConvertDocumentsToPDF.pdf";

        // const f = sp.web.getFolderByServerRelativeUrl(this.Folder_Help).files.getByName(fileName);

        // f.get().then(t => {
        //     console.log(t);
        //     const serverRelativeUrl = t["ServerRelativeUrl"];
        //     console.log(serverRelativeUrl);

        //     const a = document.createElement('a');
        //     //document.body.appendChild(a);
        //     a.href = serverRelativeUrl;
        //     a.target = "_blank";
        //     a.download = fileName;

        //     document.body.appendChild(a);
        //     console.log(a);
        //     //a.click();
        //     //document.body.removeChild(a);


        //     setTimeout(() => {
        //         window.URL.revokeObjectURL(serverRelativeUrl);
        //         window.open(serverRelativeUrl, '_blank');
        //         document.body.removeChild(a);
        //     }, 1);


        // });

    }


    private getHeaderText = (): string => {
        if (this.props.updateType === GIAAUpdateTypes.ActionUpdate)
            return "Add new action update";
        else if (this.props.updateType === GIAAUpdateTypes.Status_DateUpdate)
            return "Status/Implementation Date Update";
        else if (this.props.updateType === GIAAUpdateTypes.GIAAComment)
            return "Add Auditors Comments";
        else
            return "Add Comment or Feedback";
    }


    private saveData = (): void => {


        if (this.validateEntity()) {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);

            let f: IGIAAUpdate = { ...this.state.FormData };

            if (f.ID === 0) {
                let myfile = (document.querySelector("#fileUpload") as HTMLInputElement);
                //firts create record in the db, so we can get the ID, then use the ID to append in the file name to make file name unique
                this.giaaUpdateService.create(f).then(x => {
                    if ((f.EvIsLink === false) && myfile.files[0]) {
                        this.uploadFile(x.ID, x.UpdatedById);
                    }
                    else {
                        //its a link instead of the file, so close the form
                        console.log('evidence saved as link');
                        this.props.onSaved(f.GIAAActionStatusTypeId, f.RevisedDate);
                    }
                });
            }
            else {

                this.giaaUpdateService.updatePut(f.ID, f).then(() => this.props.onSaved(f.GIAAActionStatusTypeId, f.RevisedDate), (err) => {
                    if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error updating item`, err.message);
                });
            }
        }
    }

    private uploadFile = async (updateId: number, uploadedByUserId: number) => {
        this.setState({
            UploadStatus: "Uploading file ...",
            ShowUploadProgress: true
        });

        let myfile = (document.querySelector("#fileUpload") as HTMLInputElement).files[0];
        let fileName: string = myfile.name; //`${updateId}_${myfile.name}`;

        try {
            const response = await this.zFileService.uploadFile('GIAA', myfile, (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ UploadProgress: progress });
            });

            if (response.status === true) {
                console.log('success status', response.fileID);
                this.afterFileUpload(String(response.fileID), updateId, fileName, uploadedByUserId);
            }
            else {
                console.log('error in uploading file');
            }
            console.log('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file', error);
        }

        // this.zFileService.uploadFile('GIAA', myfile).then(response => {
        //     console.log('response', response);
        //     if (response.status === true) {
        //         console.log('success status', response.fileID);
        //         this.afterFileUpload(String(response.fileID), updateId, fileName, uploadedByUserId);
        //     }
        //     else {
        //         console.log('error in uploading file');
        //     }
        // });

    }

    private afterFileUpload = (fileUniqueID: string, updateId: number, fileName: string, uploadedByUserId: number): void => {

        const fdata = { ...this.state.FormData, "FileUniqueID": fileUniqueID, "EvFileName": fileName, "ID": updateId, "UpdatedById": uploadedByUserId };
        this.setState({
            FormData: fdata
        }, this.saveData);

    }

    public componentDidMount(): void {
        this.setState({ Loading: true });
        let loadingPromises = [this.loadLookups()];
        Promise.all(loadingPromises).then(p => this.onAfterLoad(p[1])).then(p => this.setState({ Loading: false })).catch(err => this.setState({ Loading: false }));
    }

    private loadLookups(): Promise<any> {

        let proms: any[] = [];
        proms.push(this.loadGIAAActionStatusTypes());
        return Promise.all(proms);
    }

    private loadGIAAActionStatusTypes = (): void => {
        this.giaaActionStatusTypeService.readAll().then((data: IEntity[]): IEntity[] => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "GIAAActionStatusTypes", data) });
            return data;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading GIAAActionStatusTypes lookup data`, err.message); });
    }

    private onAfterLoad = (entity: types.IEntity): void => {
        if (this.props.updateType === GIAAUpdateTypes.ActionUpdate) {
        }
        else if (this.props.updateType === GIAAUpdateTypes.Status_DateUpdate) {
            let fd: IGIAAUpdate = this.cloneObject(this.state.FormData, "RevisedDate", this.props.defaultRevDate);
            fd = this.cloneObject(fd, "GIAAActionStatusTypeId", this.props.defaultActionStatusTypeId);
            //check if targetDate is greaterthan today date and status is open then set status to overdue
            if (this.props.targetDate < new Date() && fd.GIAAActionStatusTypeId === 1) {
                console.log('target date is greater than todays date and status is open, so set status to overdue');
                fd = this.cloneObject(fd, "GIAAActionStatusTypeId", 3); //3 is overdue
            }
            this.setState({ FormData: fd, FormIsDirty: true });
        }
    }

    private clearValidations = (): void => {
        let errMsg: IErrorMessage = { ...this.state.ErrMessages };
        errMsg.ActionStatus = null;
        errMsg.Details = null;
        errMsg.FileUpload = null;
        errMsg.RevisedDate = null;
        errMsg.RequestDateChangeTo = null;
        this.setState({ ErrMessages: errMsg });
    }

    private validateEntity = (): boolean => {
        let returnVal: boolean = true;
        let errMsg: IErrorMessage = { ...this.state.ErrMessages };

        if ((this.state.FormData.UpdateDetails === null) || (this.state.FormData.UpdateDetails === '')) {
            errMsg.Details = "Details required";
            returnVal = false;
        }
        else {
            errMsg.Details = null;
        }

        if (this.props.updateType === GIAAUpdateTypes.Status_DateUpdate && this.state.FormData.GIAAActionStatusTypeId === null) {
            errMsg.ActionStatus = "Revised Recommendation Status required";
            returnVal = false;
        }
        else {
            errMsg.ActionStatus = null;
        }

        if (this.state.FormData.RequestDateChange === true && this.state.FormData.RequestDateChangeTo === null) {
            errMsg.RequestDateChangeTo = "Date required";
            returnVal = false;
        }
        else {
            errMsg.RequestDateChangeTo = null;
        }

        if (this.props.updateType === GIAAUpdateTypes.ActionUpdate && this.state.FormData.RequestClose === true) {

            const file = (document.querySelector("#fileUpload") as HTMLInputElement).files[0];

            if (file == null) {
                errMsg.FileUpload = "PDF file required";
                returnVal = false;
            }
            else {
                const fileName = file.name;
                console.log("fileName", fileName);
                const ext = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();

                if (ext === "pdf") {
                    errMsg.FileUpload = null;
                }
                else {
                    errMsg.FileUpload = "PDF file required";
                    returnVal = false;
                }
            }

        }
        else {
            errMsg.FileUpload = null;
        }

        //at the end set state
        this.setState({ ErrMessages: errMsg });
        return returnVal;
    }
    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }

    private changeTextField = (value: string, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value), FormIsDirty: true });
    }

    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, option.key), FormIsDirty: true });
    }

    protected changeCheckbox = (value: boolean, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value), /*ShowFileUpload: !value , FormIsDirty: true*/ });
    }
    private changeCheckbox_Req = (value: boolean, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value), FormIsDirty: true }, this.clearValidations);
    }

}