import * as React from 'react';
import { DetailsList, SelectionMode, IColumn, ISelection } from '@fluentui/react/lib/DetailsList';
import { SearchObjectService } from '../../services';
import { IEntity } from '../../types';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { ICommandBarItemProps, ThemeProvider } from '@fluentui/react';
import styles from '../../styles/cr.module.scss';
import '../../styles/CustomFabric2.scss';
import { CrCommandBar } from '../cr/CrCommandBar';

export interface IFilteredUpdatesListProps {
    className?: string;
    columns: IColumn[];
    items: any[];
    filterText?: string;
    onFilterChange: (value: string) => void;
    selection?: ISelection;
    onAddActionUpdate: () => void;
    onAddStatus_DateUpdate: () => void;
    onAddGIAAComments: () => void;
    onAddMiscComments: () => void;
    onDelete: () => void;
    deleteDisabled: boolean;
    viewDisabled: boolean;
    onView: () => void;
    superUserPermission: boolean;
    giaaStaffPermission: boolean;

}

export interface IFilteredUpdatesListState {
    Columns: IColumn[];
    FilteredItems: any[];
}

export class FilteredUpdatesList extends React.Component<IFilteredUpdatesListProps, IFilteredUpdatesListState> {
    constructor(props: IFilteredUpdatesListProps) {
        super(props);
        props.columns.forEach((c) => { c.onColumnClick = this._onColumnClick; });
        this.state = {
            Columns: props.columns,
            FilteredItems: props.items,
        };
    }

    public render(): JSX.Element {
        const { props, state } = this;
        const selCount: number = props.selection.getSelectedCount();

        const commandBarItems: ICommandBarItemProps[] = [];
        const farCommandBarItems: ICommandBarItemProps[] = [];

        const actionUpdateItem: ICommandBarItemProps = {
            key: 'ActionUpdate',
            text: 'Action Update',
            iconProps: { iconName: 'AddNotes' },
            onClick: props.onAddActionUpdate,
        };

        const statusImplementationDateItem: ICommandBarItemProps = {
            key: 'StatusImplementationDateUpdate',
            text: 'Status/Implementation Date Update',
            iconProps: { iconName: 'AddEvent' },
            onClick: props.onAddStatus_DateUpdate,
        };

        const giaaCommentsItem: ICommandBarItemProps = {
            key: 'GIAAComments',
            text: 'Auditors Comments',
            iconProps: { iconName: 'CommentAdd' },
            onClick: props.onAddGIAAComments,
        };

        const miscCommentsItem: ICommandBarItemProps = {
            key: 'MiscComments',
            text: 'Misc Comments',
            iconProps: { iconName: 'CommentAdd' },
            onClick: props.onAddMiscComments,
        };

        const deleteItem = {
            key: 'delete',
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
            onClick: props.onDelete,
            disabled: props.deleteDisabled
        };

        const viewItem = {
            key: 'ViewEvidence',
            text: 'View Evidence',
            iconProps: { iconName: 'View' },
            onClick: props.onView,
            disabled: props.viewDisabled
        };

        if (selCount === 0) {
            commandBarItems.push(actionUpdateItem);
        }

        if (selCount === 0 && (props.superUserPermission === true || props.giaaStaffPermission === true)) {
            commandBarItems.push(statusImplementationDateItem);
        }

        if (selCount === 0) {
            commandBarItems.push(giaaCommentsItem);
            commandBarItems.push(miscCommentsItem);
        }

        if (selCount === 1 && props.deleteDisabled === false) {
            commandBarItems.push(deleteItem);
        }

        if (selCount === 1 && props.viewDisabled === false) {
            commandBarItems.push(viewItem);
        }

        if (props.onFilterChange) {
            farCommandBarItems.push(
                {
                    key: 'filter',
                    text: 'List filter',
                    inActive: true,
                    onRender: function renderFilter() {
                        return (
                            <div className={styles.crCommandBarContainer}>
                                <SearchBox placeholder="Filter items" value={props.filterText ?? ''} onChange={(_, v) => props.onFilterChange(v)} className={styles.listFilterBox} />
                            </div>
                        );
                    }
                }
            );
        }

        return (
            <ThemeProvider>
                <div>
                    <CrCommandBar className={props.className} items={commandBarItems} farItems={farCommandBarItems} />
                </div>

                <DetailsList
                    setKey={"state.FilteredItems"}
                    selectionMode={SelectionMode.single}
                    selection={props.selection}
                    columns={state.Columns}
                    items={state.FilteredItems}
                    onRenderItemColumn={this.renderItemColumn}
                />
            </ThemeProvider>
        );
    }

    public componentDidMount(): void {
        this.setState({ FilteredItems: SearchObjectService.filterEntities(this.props.items, this.props.filterText) });
    }

    public componentDidUpdate(prevProps: IFilteredUpdatesListProps): void {
        if (prevProps.columns !== this.props.columns) {
            this.props.columns.forEach((c) => { c.onColumnClick = this._onColumnClick; });
            this.setState({ Columns: this.props.columns, FilteredItems: SearchObjectService.filterEntities(this.props.items, this.props.filterText) });
        }
        else if (prevProps.items !== this.props.items || prevProps.filterText !== this.props.filterText) {

            this.setState({ FilteredItems: SearchObjectService.filterEntities(this.props.items, this.props.filterText) });
        }
    }

    private renderItemColumn = (item: IEntity, index: number, column: IColumn) => {

        let fieldContent = item[column.fieldName as keyof IEntity] as string;
        return <span>{fieldContent}</span>;
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { Columns, FilteredItems } = this.state;
        let newItems: any[] = FilteredItems.slice();
        const newColumns: IColumn[] = Columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol: IColumn, idx: number) => {
            return column.key === currCol.key;
        })[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        newItems = this._sortItems(newItems, currColumn.fieldName || '', currColumn.isSortedDescending);
        this.setState({
            Columns: newColumns,
            FilteredItems: newItems
        });
    }

    private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
        return items.sort((a, b) => {
            if (!a.hasOwnProperty(sortBy) || !b.hasOwnProperty(sortBy)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[sortBy] === 'string') ? a[sortBy].toLowerCase() : a[sortBy];
            const varB = (typeof b[sortBy] === 'string') ? b[sortBy].toLowerCase() : b[sortBy];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return ((descending) ? (comparison * -1) : comparison);
        });
    }
}
