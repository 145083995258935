import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import styles from '../../styles/cr.module.scss';
import axios from 'axios';
import { DataContext } from '../DataContext';
import { UserAppLaunchLog } from '../../types';
import { AppIDs } from '../../AppGlobals';
import { DashboardRenderer } from '../dashboards/DashboardRenderer';
import { PrimaryButton } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { AppWelcomeButtons } from '../AppWelcomeButtons';
import { GraphRenderer } from '../charts/GraphRenderer';

interface App03WorkflowsWelcomeProps {
}

interface IAppWelcomeText {
    CenteralHeading: string;
    CenteralBody: string;
    CustomerWelcomeHeading: string;
    CustomerWelcomeBody: string;
}

export const App03WorkflowsWelcome: React.FC<App03WorkflowsWelcomeProps> = ({ }) => {
    const { dataServices } = useContext(DataContext);
    const history = useHistory();
    const [formData, setFormData] = useState<IAppWelcomeText>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isGraphDataAvailable, setIsGraphDataAvailable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const zedxNetapiURL = process.env.REACT_APP_ZedX_Net_API!;
                const zedxNetWelcomeTextApi: string = `${zedxNetapiURL}/availableapps/GetAppWelcomeText/CORWF`;
                const centeralWelcomeText = (await axios.get(zedxNetWelcomeTextApi)).data;
                const centeralWelcomeHeading = centeralWelcomeText.WelcomeHeading;
                const centeralWelcomeBody = centeralWelcomeText.WelcomeBody;

                let customerWelcomeHeading: string = '';
                let customerWelcomeBody: string = '';

                // const clDefForm = await dataServices.caDefFormService.readAll();
                // if (clDefForm.length > 0) {
                //     customerWelcomeHeading = clDefForm[0].Title ?? '';
                //     customerWelcomeBody = clDefForm[0].Details ?? '';
                // }

                setFormData({
                    CenteralHeading: centeralWelcomeHeading,
                    CenteralBody: centeralWelcomeBody,
                    CustomerWelcomeHeading: customerWelcomeHeading,
                    CustomerWelcomeBody: customerWelcomeBody
                });
                
                dataServices.userAppLaunchLogService.create(new UserAppLaunchLog(AppIDs.CORWF));
                
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const formattedCenteralBody = useMemo(() => {
        if (!formData) return '';

        return formData.CenteralBody ? formData.CenteralBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    const formattedCustomerBody = useMemo(() => {
        if (!formData) return '';

        return formData.CustomerWelcomeBody ? formData.CustomerWelcomeBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    const handleUpdatesClick = () => {
        history.push('/corporate-workflows/workflows');
    };
    const handleInsightsClick = () => {
        history.push('/corporate-workflows/insights');
    };

    return (
        <div style={{ maxWidth: '1200px' }} className={styles.cr}>
            <CrLoadingOverlay isLoading={loading} opaque={true} />
            {formData && (
                <>
                    <h1 style={{ textAlign: 'center', marginLeft: '20px' }} className={styles.welcomeL1Heading}>{formData.CenteralHeading}</h1>
                    <div className="welcomePage-container">
                        <div className="welcomePage-leftDiv">
                            <div>
                                <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCenteralBody }} />
                            </div>
                            <div>
                                {formData.CustomerWelcomeHeading?.length > 0 && <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} className={styles.welcomeBody}>{formData.CustomerWelcomeHeading}</div>}
                            </div>
                            <div>
                                {formattedCustomerBody?.length > 0 && <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCustomerBody }} />}
                            </div>
                            <br />
                            <AppWelcomeButtons
                                updatesButtonText='Manage / View Workflows'
                                onUpdatesClick={handleUpdatesClick}
                                onInsightsClick={handleInsightsClick}
                            />
                        </div>
                        <div className="welcomePage-rightDiv">
                            {/* {isGraphDataAvailable && (
                                <div className="welcomePage-chartHeading">
                                    Chart shows recommendation statuses for all reports across the department
                                </div>
                            )} */}
                            <GraphRenderer
                                graphShortname={'WorkflowWelcomeDonutChart'}
                                onGraphDataFetch={(dataAvailable) => setIsGraphDataAvailable(dataAvailable)}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>

    );
};