import React, { useContext, useState } from 'react';
import { IWithErrorHandlingProps } from '../withErrorHandling';
import { TaskStageBar } from './TaskStageBar';
import { IApp03Task, IApp03WorkflowStage } from '../../types';
import { TasksEntityList } from './TasksEntityList';
import { DataContext } from '../DataContext';
import { IUseApiProps } from '../useApi';
import { TaskPriorityColors, getTaskPriorityString } from '../../refData/WorkflowType';
import { renderAsLink, renderDateRange, renderTaskPriority } from '../cr/ListRenderers';
import useMobileDetector from '../useMobileDetector';
import { TaskFormType } from './WorkflowTasks';

interface ITaskStageContainerProps extends IUseApiProps, IWithErrorHandlingProps {
    workflowID: number;
    stage: IApp03WorkflowStage;
    counts?: number;
    totalCritical?: number;
    totalUserCritical?: number;
    archived: boolean;
    userIDFilter?: number;
    onSaveData?: () => void;
    onChangeSelectedTask?: (ID: number, showTaskForm?: TaskFormType) => void;
    loadTasksListDataCounter?: number;
    clearSelection?: boolean;
    onNewTaskClick?: (defaultStageID?: number) => void;
    taskSelectionPreservedOnEmptyClick: boolean;
}

const customStylesForList = {
    root: {
        selectors: {
            '.ms-DetailsRow.is-selected': {
                background: '#EBEBE0',
            },
            '.ms-DetailsRow:hover': {
                background: '#EBEBE0',
            },
        },
    },
};

const TaskStageContainer = (props: ITaskStageContainerProps) => {

    const isMobile = useMobileDetector();
    const taskNameWidths = isMobile ? { minWidth: 100, maxWidth: 120 } : { minWidth: 350, maxWidth: 400 };
    const { dataServices } = useContext(DataContext);
    const [showContents, setShowContents] = useState(false);
    const stageID: number = props.stage !== null ? props.stage.ID : null;

    return (

        <div style={{ width: '100%' }}>
            <TaskStageBar
                currentStage={props.stage}
                workflowID={props.workflowID}
                counts={props.counts}
                totalCritical={props.totalCritical}
                totalUserCritical={props.totalUserCritical}
                isOpen={showContents}
                onClick={() => setShowContents(!showContents)}
                onSaveStage={props.onSaveData}
                onNewTaskClick={props.onNewTaskClick}
                {...props}
            />
            {
                showContents &&
                <div>
                    <TasksEntityList<IApp03Task>
                        {...props}
                        entity='Tasks'
                        customStylesForList={customStylesForList}
                        loadDataCounter={props.loadTasksListDataCounter}
                        stageID={stageID}
                        onChangeSelectedTask={props.onChangeSelectedTask}
                        clearSelection={props.clearSelection}
                        entityName={{ Plural: 'Tasks', Singular: 'Task' }}
                        columns={[
                            { key: '1', name: 'Ref', fieldName: 'TaskRef', minWidth: 35, maxWidth: 50, isResizable: true, onRender: item => renderAsLink(item.key, item.TaskRef, (ID: number) => props.onChangeSelectedTask(ID, TaskFormType.ShortForm)) },
                            { key: '2', name: 'Task', fieldName: 'Name', ...taskNameWidths, isResizable: true, onRender: item => renderAsLink(item.key, item.Name, (ID: number) => props.onChangeSelectedTask(ID, TaskFormType.FullForm)) },
                            { key: '3', name: 'Priority', fieldName: 'TaskPriority', minWidth: 60, maxWidth: 70, isResizable: true, onRender: item => renderTaskPriority(item.TaskPriority, item.TaskPriorityColor) },
                            { key: '4', name: 'Dates', minWidth: 100, maxWidth: 120, isResizable: true, isCollapsible: true, onRender: item => renderDateRange(item.StartDate, item.EndDate) },
                            { key: '5', name: 'Assigned To', fieldName: 'AssignedTo', minWidth: 150, maxWidth: 200, isResizable: true, isCollapsible: true, isMultiline: true },
                        ]}
                        loadListItems={showClosedEntities => dataServices.app03TaskService.readAllForList(showClosedEntities, props.workflowID, stageID, props.archived, props.userIDFilter)}
                        mapEntitiesToListItems={tasks => tasks.map(t => {

                            return {
                                key: t.ID,
                                Name: t.Title,
                                TaskRef: t.Workflow?.RefColumnID === 1 ? t.TaskRef : t.ExternalRef,
                                TaskPriority: getTaskPriorityString(t.TaskPriorityID),
                                TaskPriorityColor: TaskPriorityColors[t.TaskPriorityID],
                                StartDate: t.StartDate,
                                StartDateValue: t.StartDate?.valueOf(),
                                EndDate: t.EndDate,
                                EndDateValue: t.EndDate?.valueOf(),
                                AssignedTo: [
                                    t.App03TaskUsers && t.App03TaskUsers.some(tu => tu.User != null) ? `${t.App03TaskUsers.map(tu => tu.User?.Title).join(', ')}` : null,
                                ].filter(Boolean).join(', '),
                            };
                        })}
                        onCheckDelete={id => dataServices.app03TaskService.entityChildren(id)}
                        onDelete={id => dataServices.app03TaskService.delete(id)}
                        selectionPreservedOnEmptyClick={props.taskSelectionPreservedOnEmptyClick}
                    />
                </div>
            }

        </div>

    );
};

export default TaskStageContainer;