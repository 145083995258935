import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../styles/cr.module.scss';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from '../cr/CrCommandBar';
import TaskStageContainer from './TaskStageContainer';
import { DataContext } from '../DataContext';
import { IApp03Task, IApp03Workflow, IApp03WorkflowStage } from '../../types';
import { TaskPriority, WorkflowType, getWorkflowTypeString } from '../../refData/WorkflowType';
import { TaskForm } from './TaskForm';
import { OrbUserContext } from '../OrbUserContext';
import { TaskView } from './TaskView';
import { Toggle } from '@fluentui/react';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { WFStageForm } from './WFStageForm';
import { TaskShortForm } from './TaskShortForm';
import { TaskFullForm } from './TaskFullForm';
import { TaskFormPage } from './TaskFormPage';

interface WorkflowTasksProps extends IWithErrorHandlingProps {
    apiConnected: boolean;
}

export enum TaskFormType {
    ShortForm = "ShortForm",
    FullForm = "FullForm",
}

const WorkflowTasks = (props: WorkflowTasksProps) => {

    const { dataServices } = useContext(DataContext);
    const { userContext } = useContext(OrbUserContext);
    const { workflowIdForTasks } = useParams<{ workflowIdForTasks: string }>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [justMyTasks, setJustMyTasks] = useState<boolean>(false);
    const [showTaskForm, setShowTaskForm] = useState<TaskFormType>(null);
    //const [showTaskFullForm, setShowTaskFullForm] = useState<TaskFormType>(null);
    const [showTaskView, setShowTaskView] = useState(false);
    const [selectedTask, setSelectedTask] = useState<number>(null);
    const [showStageForm, setShowStageForm] = useState(false);
    const [editStage, setEditStage] = useState(false);
    const [selectedStageToEdit, setSelectedStageToEdit] = useState<number>(null);

    const [loadStageDataCount, setLoadStageDataCount] = useState(0);
    const [loadTasksListDataCounter, setLoadTasksListDataCounter] = useState(0);
    const [workflow, setWorkflow] = useState<IApp03Workflow>();
    const [newTaskDefaultStageID, setNewTaskDefaultStageID] = useState<number>(null);

    const [taskSelectionPreservedOnEmptyClick, setTaskSelectionPreservedOnEmptyClick] = useState<boolean>(false);

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'NewTask',
            text: 'New Task',
            iconProps: { iconName: 'Add' },
            onClick: (ev, item) => {
                handleAddNewTask();
            },
        },
        (selectedTask !== null) && {
            key: 'EditTask',
            text: 'Edit Task',
            iconProps: { iconName: 'Edit' },
            onClick: (ev, item) => {
                setTaskSelectionPreservedOnEmptyClick(true);
                setShowTaskForm(TaskFormType.FullForm);
            },
        },
        (selectedTask !== null) && {
            key: 'View',
            text: 'View',
            iconProps: { iconName: 'View' },
            onClick: (ev, item) => {
                setShowTaskView(true);
            },
        },
        (selectedTask !== null && workflow?.WorkflowTypeID === WorkflowType.Linked) && {
            key: 'Updates',
            text: 'Updates',
            iconProps: { iconName: 'MiniExpand' },
            onClick: (ev, item) => {
                const task: IApp03Task = workflow?.App03Tasks?.find(t => t.ID === selectedTask);
                const dateString: any = task.UpdatePeriod;
                const updatePeriod: Date = new Date(dateString);
                if (!isNaN(updatePeriod.getTime())) {
                    const formattedDate: string = updatePeriod.toISOString().split('T')[0]; //'YYYY-MM-DD'
                    const projectID: number = task?.ProjectID;
                    if (projectID > 0) {
                        history.push(`/performance-reporting/sign-off-for-project/${projectID}/${workflow.ID}/${formattedDate}`);
                    }

                } else {
                    console.log('Task update period is not a valid date string.');
                }

            },
        },
        {
            key: 'NewStage',
            text: 'New Stage',
            iconProps: { iconName: 'CircleAddition' },
            onClick: (ev, item) => {
                setSelectedStageToEdit(null);
                setEditStage(false);
                setShowStageForm(true);
            },
        },
        {
            key: 'EditStage',
            text: 'Edit Stage',
            iconProps: { iconName: 'FullWidthEdit' },
            onClick: (ev, item) => {
                setSelectedStageToEdit(null); //make initially null
                setEditStage(true);
                setShowStageForm(true);
            },
        },
        {
            key: 'Workflows',
            text: 'Workflows',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {
                history.push('/corporate-workflows/workflows');
            },
        },
    ].filter(Boolean);

    const handleAddNewTask = (defaultStageID?: number) => {
        setNewTaskDefaultStageID(defaultStageID);
        setSelectedTask(null);
        setShowTaskForm(TaskFormType.FullForm);
    }

    const showTaskViewToggle = () => {
        setShowTaskView(!showTaskView);
    }

    useEffect(() => {
        if (showTaskForm === TaskFormType.FullForm) {
            const scrollableParent = document.querySelector('#TaskFormPageContainer');
            console.log('Scrollable Parent:', scrollableParent);
            scrollableParent?.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [showTaskForm]);


    useEffect(() => {
        const fetchWorkflow = async () => {
            if (Number(workflowIdForTasks) > 0) {
                setIsLoading(true);
                try {
                    const workflow: IApp03Workflow = await dataServices.app03WorkflowService.read(Number(workflowIdForTasks), false, false, ['App03Tasks($select=ID,WorkflowStageID,TaskPriorityID,Archived,ProjectID,UpdatePeriod;$expand=App03TaskUsers($select=ID,UserID))', 'App03WorkflowStages($select=ID,Title,App03WorkflowID,DisplayOrder;$orderby=DisplayOrder)']);
                    setWorkflow(workflow);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                finally {
                    setIsLoading(false);
                }
            }
        };

        fetchWorkflow();
    }, [workflowIdForTasks, loadStageDataCount, loadTasksListDataCounter]);

    const onSaveStage = (): void => {
        setLoadStageDataCount(loadStageDataCount + 1);
    }

    const handleEditStageSelection = (stageID: number): void => {
        setSelectedStageToEdit(stageID);
    }

    const handleChangeSelectedTask = (taskID: number, showTaskForm?: TaskFormType) => {
        console.log('in handleChangeSelectedTask', taskID, showTaskForm);
        setSelectedTask(taskID);
        if (showTaskForm) {
            if (showTaskForm === TaskFormType.FullForm) {
                //user clicks on blue task title to open full form in edit mode
                setShowTaskForm(TaskFormType.FullForm);
                setTaskSelectionPreservedOnEmptyClick(true);
            }
            else {
                setShowTaskForm(TaskFormType.ShortForm);
            }
        }
        else {
            setShowTaskForm(null);
        }
    };

    const showStageFormToggle = () => {
        setShowStageForm(!showStageForm);
    }

    const taskFormSharedProps = {
        showForm: true,
        workflowID: workflow?.ID,
        newTaskStageID: newTaskDefaultStageID || workflow?.NewTaskDefaultStageID,
        entityId: selectedTask,
        onSaved: () => {
            setTaskSelectionPreservedOnEmptyClick(false);
            setShowTaskForm(null);
            setLoadTasksListDataCounter(loadTasksListDataCounter + 1);
        },
        onCancelled: () => {
            setTaskSelectionPreservedOnEmptyClick(false);
            setShowTaskForm(null);
        },
        ...props,
    };

    return (
        <div className={`${styles.cr} ${styles.updatesContainer}`} style={{ width: 'calc(100% - 10px)' }}>
            {
                workflow &&
                showTaskForm &&
                showTaskForm === TaskFormType.FullForm &&
                <div id='TaskFormPageContainer'>
                    <TaskFormPage
                        workflowTitle={workflow.Title}
                        {...taskFormSharedProps} />
                </div>

            }

            {
                <div style={{ display: (showTaskForm === TaskFormType.FullForm) ? 'none' : 'block' }}>
                    <CrLoadingOverlay isLoading={isLoading} opaque={true} />
                    <CrCommandBar items={commandBarItems} />
                    {
                        showStageForm &&
                        <WFStageForm
                            workflowID={Number(workflowIdForTasks)}
                            editRequest={editStage}
                            showForm={showStageForm}
                            onEditStageSelection={handleEditStageSelection}
                            entityId={selectedStageToEdit}
                            {...props}
                            onSaved={() => {
                                onSaveStage();
                                showStageFormToggle();
                            }}
                            onCancelled={showStageFormToggle}
                            {...props}
                        />
                    }
                    <div>
                        <div className='tasks-header'>
                            <div className='tasks-info'>
                                <div>
                                    <h2 className={styles.listTitle}>{workflow && workflow.Title}</h2>
                                    <div style={{ fontStyle: 'italic', paddingLeft: '10px', paddingBottom: '10px' }}>{workflow && getWorkflowTypeString(workflow.WorkflowTypeID)} workflow</div>
                                </div>
                            </div>
                            <div className="tasks-toggle">
                                <Toggle
                                    onText={`Just my tasks`}
                                    offText={`Just my tasks`}
                                    checked={justMyTasks}
                                    onChange={() => {
                                        setJustMyTasks(!justMyTasks);
                                        setLoadTasksListDataCounter(loadTasksListDataCounter + 1);
                                    }}
                                />
                            </div>
                        </div>

                        {workflow && workflow?.App03WorkflowStages?.map((stage, index) => (
                            <TaskStageContainer
                                key={index}
                                workflowID={workflow.ID}
                                stage={stage}
                                counts={workflow.App03Tasks?.filter(t => t.WorkflowStageID === stage.ID && t.Archived !== true).length ?? 0}
                                totalCritical={workflow.App03Tasks?.filter(t => t.WorkflowStageID === stage.ID && t.TaskPriorityID === TaskPriority.Critical && t.Archived !== true).length ?? 0}
                                totalUserCritical={workflow.App03Tasks?.filter(t => t.WorkflowStageID === stage.ID && t.TaskPriorityID === TaskPriority.Critical && t.App03TaskUsers.some(user => user.UserID === userContext.UserId)).length ?? 0}
                                archived={null}
                                userIDFilter={justMyTasks === true ? userContext.UserId : null}
                                onSaveData={onSaveStage}
                                onChangeSelectedTask={handleChangeSelectedTask}
                                loadTasksListDataCounter={loadTasksListDataCounter}
                                clearSelection={selectedTask === null}
                                onNewTaskClick={handleAddNewTask}
                                taskSelectionPreservedOnEmptyClick={taskSelectionPreservedOnEmptyClick}
                                {...props}
                            />
                        ))}

                        {/* for archived */}
                        {workflow &&
                            <TaskStageContainer
                                workflowID={workflow.ID}
                                stage={null}
                                archived={true}
                                userIDFilter={justMyTasks === true ? userContext.UserId : null}
                                counts={workflow.App03Tasks?.filter(t => t.Archived === true).length ?? 0}
                                onSaveData={onSaveStage}
                                onChangeSelectedTask={handleChangeSelectedTask}
                                loadTasksListDataCounter={loadTasksListDataCounter}
                                clearSelection={selectedTask === null}
                                taskSelectionPreservedOnEmptyClick={taskSelectionPreservedOnEmptyClick}
                                {...props}
                            />
                        }

                        {
                            workflow && showTaskForm == TaskFormType.ShortForm &&
                            <TaskShortForm {...taskFormSharedProps} />
                        }

                        {
                            workflow && showTaskView &&
                            <TaskView
                                showForm={showTaskView}
                                entityId={selectedTask}
                                entityName='Task'
                                onClosed={showTaskViewToggle}
                                loadEntity={() => dataServices.app03TaskService.readForView(selectedTask)}
                            />
                        }

                    </div>
                </div>
            }



        </div>

    );
};

export default withErrorHandling(WorkflowTasks);