import React from 'react';
import { PrimaryButton } from '@fluentui/react';

interface AppWelcomeButtonsProps {
    onUpdatesClick: () => void;
    onInsightsClick: () => void;
    updatesButtonText?: string
}

export const AppWelcomeButtons: React.FC<AppWelcomeButtonsProps> = ({ updatesButtonText, onUpdatesClick, onInsightsClick }) => {
    return (
        <div className="welcomescreen-button-container">
            <PrimaryButton
                text={updatesButtonText ?? 'Start / View Updates'}
                className={updatesButtonText ? 'auto' : "button"}
                onClick={onUpdatesClick}
            />
            <PrimaryButton
                text="View Insights"
                className="button"
                onClick={onInsightsClick}
            />
        </div>
    );
};
